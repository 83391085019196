<script setup lang="ts">
import { apiQuiz } from '@api/quiz';
import { DropdownSearch, BaseCheck } from '@components/base';
import { useFetch } from '@helpers/use-fetch';
import { AllergySelect, AllergyItem } from '@models/quiz';
import { debounce } from 'lodash';
import { computed } from 'vue';

type Props = {
  modelValue?: AllergyItem[]
  content?: AllergySelect
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value?: AllergyItem[]]
}>();

const allergiesFetch = useFetch(apiQuiz.getAllergies);
const loading = computed(() => allergiesFetch.loading.value);
const options = computed(() => allergiesFetch.response.value?.data || []);

const search = debounce((value: string) => {
  allergiesFetch.execute({ q: value });
}, 800);

const allergyModel = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const noAllergiesModel = computed({
  get() {
    return props.modelValue?.length === 0;
  },
  set(value) {
    const newValue = value ? [] : undefined;
    emit('update:modelValue', newValue);
  },
});
</script>

<template>
  <div class="allergy-answer f-col-4 flex flex-col items-center">
    <DropdownSearch
      v-model="allergyModel"
      :options="options"
      :loading="loading"
      :search-placeholder="content?.placeholder"
      search-input-placeholder="Your allergy"
      :min-length="3"
      min-length-message="Enter at least 3 characters"
      no-results-message="No allergens found"
      :option-label="(option: AllergyItem) => option.Name"
      :custom-value="(value) => ({
        Name: value,
        Code: null,
        CodeType: null,
        RxCUI: null,
      })"
      track-by="Name"
      multiple
      @search="search"
    />

    <BaseCheck
      v-model="noAllergiesModel"
      class="mt-30 w-full sm:mt-48"
      :size="20"
      type="checkbox"
      styled
      :data-testid="noAllergiesModel
        ? 'quiz-checkbox-selected'
        : 'quiz-checkbox'"
    >
      I don’t have any allergies
    </BaseCheck>
  </div>
</template>

<style lang="scss" scoped></style>
