<script setup lang="ts">
import { StepAnswer, MaskInput, MASK_INPUT_FIELD_TYPE } from '@models/quiz';
import { vMaska } from 'maska';
import { ref, computed, onMounted } from 'vue';

type Props = {
  content: MaskInput
  modelValue: StepAnswer<MaskInput> | undefined
  columns?: string
}

const props = withDefaults(defineProps<Props>(), {
  columns: '4',
});

const emit = defineEmits<{ 'update:modelValue': [value: string] }>();

const inputRef = ref<HTMLInputElement | null>(null);

const colClass = computed(() => {
  if (props.content.fieldType === MASK_INPUT_FIELD_TYPE.NAME) {
    return '!w-auto';
  }
  return `sm:f-col-${props.columns}`;
});

const template = computed(() => props.content.mask?.match(/{.+}/g)?.[0]);
const placeholder = computed(() => template.value?.slice(1, template.value.length - 1));

const textArray = computed(() => {
  return template.value ? props.content.mask?.split(template.value) : props.content.mask;
});

const model = computed({
  get() {
    return props.modelValue?.value ?? '';
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const nameMaskOptions = {
  preProcess(value: string) {
    return value
      .replaceAll(/^\s+/gi, '')
      .replaceAll(/\s{2,}/gi, ' ');
  },
};

const heightMaskOptions = {
  mask: (value: string) => {
    if (value.length <= 4) {
      setTimeout(() => {
        inputRef.value?.setSelectionRange(4, 4);
      }, 0);

      return 'A\' A"';
    }

    return 'A\' AB"';
  },
};

onMounted(() => {
  const inputs = document.querySelectorAll('.mask-input-answer__field');
  const order = Array.from(inputs).indexOf(inputRef.value as HTMLElement);

  if (order === 0) {
    inputRef.value?.focus();
  }
});
</script>

<template>
  <div
    class="mask-input-answer f-col-full inline-block"
    :class="colClass"
  >
    <label class="flex flex-wrap justify-center text-30 font-bold">
      <span
        v-if="textArray?.[0]"
        :class="{
          'w-full sm:w-auto': content.fieldType === MASK_INPUT_FIELD_TYPE.NUMBER
        }"
      >
        {{ textArray?.[0] }}
      </span>

      <span class="relative">
        <span
          class="invisible"
          :class="{ 'pr-[0.3em]': content.fieldType === MASK_INPUT_FIELD_TYPE.HEIGHT }"
        >
          {{ model || placeholder }}
        </span>

        <input
          v-if="content.fieldType === MASK_INPUT_FIELD_TYPE.TEXT"
          ref="inputRef"
          v-model="model"
          class="mask-input-answer__field"
          type="text"
          :placeholder="placeholder"
          spellcheck="false"
        >
        <input
          v-else-if="content.fieldType === MASK_INPUT_FIELD_TYPE.NUMBER"
          ref="inputRef"
          v-model="model"
          v-maska
          data-maska="A.BB"
          data-maska-tokens="A:\d:multiple|B:\d"
          class="mask-input-answer__field"
          type="text"
          :placeholder="placeholder"
          spellcheck="false"
        >
        <input
          v-else-if="content.fieldType === MASK_INPUT_FIELD_TYPE.NAME"
          ref="inputRef"
          v-model="model"
          v-maska:[nameMaskOptions]
          data-maska="A"
          data-maska-tokens="A:[A-z'-\s]:multiple"
          data-maska-eager
          class="mask-input-answer__field"
          :class="{ '': !model }"
          type="text"
          :placeholder="placeholder"
          spellcheck="false"
        >
        <input
          v-else-if="content.fieldType === MASK_INPUT_FIELD_TYPE.HEIGHT"
          ref="inputRef"
          v-model="model"
          v-maska:[heightMaskOptions]
          data-maska-tokens="A:\d|B:\d:optional"
          data-maska-eager
          class="mask-input-answer__field"
          type="text"
          :placeholder="placeholder"
          spellcheck="false"
        >
      </span>

      {{ textArray?.[1] }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
.mask-input-answer__field {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  background-color: initial;
  font-size: 30px;

  &::placeholder {
    color: theme('colors.purple');
  }
}
</style>
