<script setup lang="ts">
import { QuizNavigation } from '@components/quiz';
import { MODAL_TYPE } from '@models/modals';
import { UserRegisterPayload } from '@models/user';
import { useQuizStore, useUserStore } from '@stores/index';
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';
import { ref, reactive, onMounted } from 'vue';

const emit = defineEmits<{ success: [], back: [] }>();
const userStore = useUserStore();
const inputRef = ref<HTMLElement | null>(null);

const siteHandle = window.siteHandle;

const formData = reactive<UserRegisterPayload>({
  email: '',
  password: '',
});

const rules = {
  email: { email, required },
  password: { required, minLength: minLength(6) },
};

const quizStore = useQuizStore();
const v$ = useVuelidate(rules, formData);

const onSubmit = async () => {
  formData.name = quizStore.fullName;
  formData.siteHandle = siteHandle;

  const isValid = await v$.value.$validate();

  if (!isValid) {
    return;
  }

  const result = await userStore.register(formData);

  if (result) {
    emit('success');
  }
};

onMounted(() => {
  inputRef.value?.focus();
});
</script>

<template>
  <div class="quiz-signup sm:f-col-4 f-col-full">
    <div class="sm:max-w-[360px] mx-auto">
      <form class="flex flex-col gap-20">
        <div>
          <lable class="input-label">your email address</lable>
          <input
            ref="inputRef"
            v-model.trim="formData.email"
            class="input w-full"
            :class="{ 'input--error': v$.email.$error }"
            type="text"
            placeholder=""
          >
          <p
            v-for="error in v$.email.$errors"
            :key="error.$uid"
            class="text-red"
          >
            {{ error.$message }}
          </p>
        </div>
        <div>
          <lable class="input-label">Your password</lable>
          <input
            v-model="formData.password"
            class="input w-full"
            :class="{ 'input--error': v$.password.$error }"
            type="password"
            placeholder=""
          >
          <p
            v-for="error in v$.password.$errors"
            :key="error.$uid"
            class="text-red"
          >
            {{ error.$message }}
          </p>
        </div>
        <div class="mt-40">
          <QuizNavigation
            class="relative sm:self-center"
            next-class="!min-w-[180px]"
            back-class="mr-10"
            next-text="Create account"
            :next-loading="userStore.loading"
            :next-disabled="v$.$error"
            @next="onSubmit"
            @back="emit('back')"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-signup__text {
  p {
    margin-top: 0;
  }
}
</style>
