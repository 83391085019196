<script setup lang="ts">
import ChevronRightIcon from '@assets/icons/chevron-right.svg';
import SpinnerIcon from '@assets/icons/spinner.svg';
import { BaseButton } from '@components/base';
import { useMemorize } from '@helpers/use-memorize';
import { StepContentBody, StepAnswer, CommerceProduct, STEP_ANSWER_CONTENT_TYPE } from '@models/quiz';
import { useAnalyticsStore } from '@stores/analytics';
import { useCartStore } from '@stores/cart';
import { watchOnce } from '@vueuse/core';
import { computed } from 'vue';

type Answer = StepAnswer<CommerceProduct>

type Props = {
  columns?: string
  content: StepContentBody
  answers: Answer[]
}

const props = withDefaults(defineProps<Props>(), {
  columns: '6',
});

const cartStore = useCartStore();
const { beginCheckout } = useAnalyticsStore();
const memorize = useMemorize('quiz-starter-checkout');

const columnClass = computed(() => {
  return `f-col-${props.columns}`;
});

// Plan product price
const planPrice = computed(() => {
  const planAnswer = props.answers.find((answer) => answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.COMMERCE_PLAN);
  return planAnswer?.content[0].content.price;
});

const cartId = computed(() => cartStore.cart?.id);

watchOnce(cartId, () => {
  if (!cartId.value) {
    return;
  }

  if (memorize.check(cartId.value)) {
    return;
  }

  beginCheckout(
    cartStore.toAnalyticProducts(cartStore.cartItems),
    (cartStore.cart?.total ?? 0) / 100,
    cartId.value,
  );

  memorize.commit(cartId.value);
});
</script>

<template>
  <div class="quiz-starter">
    <div class="f-row">
      <div :class="columnClass">
        <h1 class="text-42 font-extralight leading-none sm:text-60">
          {{ content.header }}
        </h1>
        <div class="soft-shadow mt-30 flex min-h-[470px] rounded-[20px] bg-white p-20 sm:rounded-[40px] sm:p-40 sm:pl-0">
          <SpinnerIcon
            v-if="cartStore.cartUpdateLoading"
            class="m-auto h-48 w-48"
          />

          <div
            v-else
            class="flex flex-col items-start gap-x-10 gap-y-20 sm:flex-row sm:pb-50"
          >
            <figure class="sm:pt-56">
              <img
                :src="content.picture"
                alt=""
              >
            </figure>

            <div class="w-full shrink-0 sm:max-w-[303px]">
              <h2 class="text-24 sm:text-37 sm:font-light sm:leading-none">
                {{ content.subheader }}
              </h2>

              <p class="mt-10 text-18 font-bold text-purple sm:text-24">
                {{ cartStore.formattedCartTotal }}
              </p>

              <ul class="mt-30 flex flex-col gap-12">
                <li
                  v-for="(item, index) in content.iconTextList"
                  :key="index"
                  class="flex items-center gap-14"
                >
                  <img
                    class="w-22 self-start"
                    :src="item.icon"
                    alt=""
                  >
                  <p class="text-14">
                    {{ item.text }}
                  </p>
                </li>
              </ul>

              <div class="mt-12 border-t border-light-grey-30 pt-20">
                <p class="text-14 font-bold text-purple">
                  {{ planPrice }} medical coverage thereafter
                </p>
              </div>

              <BaseButton
                class="mt-32 min-w-[145px] bg-purple text-white hover:bg-deep-blue"
                text="Select"
                animated
                :loading="cartStore.createPaymentLoading"
                @click="cartStore.createPayment"
              >
                <template #icon-right>
                  <ChevronRightIcon />
                </template>
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
