<script setup lang="ts">
import AbortIcon from '@assets/icons/abort.svg';
import LogoIcon from '@assets/icons/logo.svg';
import ProfileIcon from '@assets/icons/profile.svg';
import RestartIcon from '@assets/icons/restart.svg';
import { NavigationMenu } from '@components/navigation-menu';
import { QuizLoader, QuizStep } from '@components/quiz';
import { MODAL_TYPE } from '@models/modals';
import { QUIZ_TYPE, STEP_TYPE, StepAnswer } from '@models/quiz';
import { useQuizStore, useUserStore } from '@stores/index';
import { ref } from 'vue';

type Props = {
  handle: QUIZ_TYPE;
}

const props = defineProps<Props>();

const userStore = useUserStore();
const quizStore = useQuizStore();
const loading = ref(false);

userStore.getInfo().then(() => {
  quizStore.getQuiz(props.handle);
});

const next = (payload?: StepAnswer<any>[], stepId?: number) => {
  if (payload) {
    loading.value = true;
    quizStore.setAnswer(payload, stepId)
      .finally(() => {
        loading.value = false;
      });
  }

  quizStore.next();
};
const siteHandle = window.siteHandle;
</script>

<template>
  <div class="quiz">
    <header class="pb-18 pt-30 lg:pt-42">
      <div class="container flex items-center justify-between gap-16">
        <a
          href="/"
        >
          <LogoIcon class="h-[31px] w-[142px] text-deep-blue" />
        </a>

<!--        <NavigationMenu
          v-if="!userStore.info?.isGuest"
          :items="$root.userMenuItems"
        >
          <ProfileIcon class="h-[32px] w-[32px]" />
        </NavigationMenu>

        <button
          v-else
          class="text-purple hover:text-deep-blue"
          type="button"
          @click="$root.setModal(MODAL_TYPE.LOGIN, true)"
        >
          <ProfileIcon class="h-[32px] w-[32px]" />
        </button>-->
      </div>
    </header>

    <Transition
      name="fade"
      mode="out-in"
    >
      <QuizLoader v-if="quizStore.loading">
        Hang tight while we prepare some questions.
      </QuizLoader>

      <div
        v-else
        class="flex flex-col pt-8 sm:pt-0"
      >
        <Transition
          name="quiz-slide"
          mode="out-in"
        >
          <div
            v-if="quizStore.currentStep"
            :key="quizStore.currentStep.id"
            class="sm:my-auto"
          >
            <QuizStep
              :step="quizStore.currentStep"
              :answers="quizStore.answers[quizStore.currentStep.id]"
              :position="quizStore.counter"
              @next="next"
              @back="quizStore.back"
            />
          </div>
        </Transition>
      </div>
    </Transition>

    <footer class="mt-auto py-20">
      <div class="container">
        <div class="flex flex-wrap justify-center md:justify-between items-center gap-x-20">
          <div class="hidden md:flex items-center gap-20">
            <Transition name="fade">
              <button
                v-if="!quizStore.paid && !quizStore.loading"
                class="btn-icon inline-flex"
                type="button"
                @click="quizStore.restart"
              >
                <RestartIcon />
                Restart intake
              </button>
            </Transition>
            <Transition name="fade">
              <button
                v-if="!quizStore.paid && !quizStore.loading"
                class="btn-icon inline-flex"
                type="button"
                @click="quizStore.abort"
              >
                <AbortIcon />
                Delete Intake
              </button>
            </Transition>
          </div>
          <Transition name="fade">
            <div
              v-if="(userStore.info?.isGuest && quizStore.currentStep?.type !== STEP_TYPE.AUTH)
              || quizStore.loading"
            >
              <button
                class="btn-icon inline-flex"
                type="button"
                data-testid="quiz-login"
                @click="$root.setModal(MODAL_TYPE.LOGIN, true)"
              >
                <ProfileIcon />
                <span>Login <i>to your account</i></span>
              </button>
            </div>
          </Transition>
          <Transition name="fade">
            <button
                v-if="!quizStore.paid && !quizStore.loading"
                class="btn-icon inline-flex md:hidden"
                type="button"
                @click="quizStore.restart"
            >
              <RestartIcon />
              Restart <i>intake</i>
            </button>
          </Transition>
          <Transition name="fade">
            <button
                v-if="!quizStore.paid && !quizStore.loading"
                class="btn-icon inline-flex md:hidden"
                type="button"
                @click="quizStore.abort"
            >
              <AbortIcon />
              Delete Intake
            </button>
          </Transition>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.btn-icon {
  padding: 9px;
  border-radius: 20px;
  align-items: center;
  gap: 10px;
  border: 1px solid transparent;
  transition-duration: 0.3s;
  transition-property: border-color;
  &:hover {
    border-color: theme('colors.charcoal');
  }
  i {
    font-style: normal;
    @include down('sm') {
      display: none;
    }
  }
}
</style>
