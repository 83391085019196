<script setup lang="ts">

import { StepContentBody } from '@models/quiz';
import { QuizNavigation } from './index';

type Props = {
  content: StepContentBody
  backHidden?: boolean
}

withDefaults(defineProps<Props>(), {
  backHidden: false,
});

const emit = defineEmits<{
  back: [],
  next: []
}>();
</script>

<template>
  <div class="quiz-image-text">
    <div class="f-row gap-y-30 sm:gap-y-48 lg:!flex-nowrap">
      <div
        v-if="content.header"
        class="f-col-full sm:f-col-4 lg:pt-56"
      >
        <h1 class="text-24 sm:text-60 sm:font-extralight sm:leading-none">
          {{ content.header }}
        </h1>

        <div
          v-if="content.text"
          class="text mt-30 text-16 first:mt-0 sm:mt-48 sm:text-20"
          v-html="content.text"
        />

        <div class="mt-48 hidden lg:block">
          <QuizNavigation
            :back-hidden="backHidden"
            @next="emit('next')"
            @back="emit('back')"
          />
        </div>
      </div>

      <div class="quiz-image-text__content f-col-full sm:f-col-4 lg:order-first">
        <img
          v-if="content.photo"
          class="quiz-image-text__image mb-20 sm:mb-30"
          :src="content.photo"
          alt=""
        >

        <div
          v-if="content.quote"
          class="lg:ml-48 lg:max-w-[400px]"
        >
          <blockquote
            class="text-16 sm:text-20"
            v-html="content.quote"
          />

          <div
            v-if="content.person || content.lost"
            class="mt-12 flex sm:justify-end"
          >
            <div class="flex gap-[0.21em] sm:block sm:min-w-[145px]">
              <p class="text-16 font-bold text-purple sm:text-24 sm:font-normal sm:text-deep-blue">
                {{ content.person }}<span class="font-normal sm:hidden">,</span>
              </p>
              <p class="text-16 text-purple sm:mt-4">
                {{ content.lost }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full lg:hidden">
        <QuizNavigation
          :back-hidden="backHidden"
          @next="emit('next')"
          @back="emit('back')"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-image-text__content {
  padding: 20px;
  border-radius: 30px;
  box-shadow: $soft-shadow;
  background-color: var(--color-white);

  @include up('sm') {
    padding: 0;
    box-shadow: none;
    background-color: initial;
  }
}

.quiz-image-text__image {
  width: 100%;
  height: 54vw;
  object-fit: cover;
  object-position: 50% 20%;
  border-radius: 15px;

  @include up('sm') {
    max-width: 362px;
    height: auto;
    border-radius: 40px;
    box-shadow: $soft-shadow,
    80px 145px 15px var(--color-light-grey-30);
  }
}
</style>
