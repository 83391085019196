<script setup lang="ts">
import RestartIcon from '@assets/icons/restart.svg';
import { QUIZ_TYPE, StepAnswer } from '@models/quiz';
import { useQuizStore, useUserStore } from '@stores/index';
import { computed } from 'vue';
import { QuizStep } from './index';

type Props = {
  handle: QUIZ_TYPE;
}

const props = defineProps<Props>();

const quizStore = useQuizStore();
const userStore = useUserStore();

const onlyQuiz = computed(() => (new URLSearchParams(window.location.search)).get('only-quiz') ?? false);

quizStore.getQuiz(props.handle);
userStore.getInfo();

const next = (payload?: StepAnswer<any>[], stepId?: number) => {
  if (payload) {
    quizStore.setAnswer(payload, stepId);
  }
  quizStore.next();
};
</script>

<template>
  <div class="flex flex-col gap-[100px] py-[100px]">
    <template
      v-for="(step, index) in quizStore.steps"
      :key="step.id"
    >
      <QuizStep
        :step="step"
        :position="index"
        @next="next"
        @back="quizStore.back"
      />
      <hr>
      <template v-if="!onlyQuiz">
<!--        <div>
          <pre class="whitespace-pre-wrap px-[20px]">{{ step }}</pre>
        </div>
        <hr>-->
      </template>
    </template>

    <div class="flex justify-center px-[20px]">
      <button
        class="inline-flex items-center gap-10 align-top hover:text-purple"
        type="button"
        @click="quizStore.restart"
      >
        <RestartIcon />
        Restart Quiz
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
