<script setup lang="ts">
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import { BaseDialog, BaseButton } from '@components/base';
import { MODAL_TYPE } from '@models/modals';
import { UserLoginPayload } from '@models/user';
import { useUserStore } from '@stores/index';
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';
import { ref, reactive, computed, watch, nextTick } from 'vue';

type Props = {
  visibility?: boolean
};

const props = defineProps<Props>();
const emit = defineEmits<{
  'update:visibility': [value: boolean],
  success: []
}>();
const userStore = useUserStore();
const inputRef = ref<HTMLElement | null>(null);

const formData = reactive<UserLoginPayload>({
  loginName: '',
  password: '',
});

const rules = {
  loginName: { email, required },
  password: { required, minValue: minLength(6) },
};

const v$ = useVuelidate(rules, formData);

const model = computed({
  get() {
    return props.visibility;
  },
  set(value) {
    emit('update:visibility', value);
  },
});

const onSubmit = async () => {
  const isValid = await v$.value.$validate();

  if (!isValid) {
    return;
  }

  const result = await userStore.login(formData);

  if (result) {
    emit('success');
  }
};

watch(() => props.visibility, async (visibility) => {
  if (!visibility) {
    return;
  }
  await nextTick();
  inputRef.value?.focus();
});
</script>

<template>
  <BaseDialog
    v-model:visibility="model"
    class="login-modal"
    data-testid="login-modal"
  >
    <form @submit.prevent="onSubmit">
      <div
        class="text-36 font-light pl-10 leading-106"
        data-testid="login-modal-header"
      >
        Login to your account:
      </div>

      <div class="mt-60 sm:mt-40 flex flex-col gap-20">
        <div>
          <label
            class="text-11 text-light-grey font-bold leading-120 uppercase mb-10 px-16 block"
            for="login-email-input"
          >
            Email address
          </label>
          <input
            id="login-email-input"
            ref="inputRef"
            v-model.trim="formData.loginName"
            class="input input--no-shadow w-full"
            :class="{ 'input--error': v$.loginName.$error }"
            type="text"
            placeholder="Email Address"
            data-testid="login-email-input"
          >
          <p
            v-for="error in v$.loginName.$errors"
            :key="error.$uid"
            class="text-red pt-5"
            data-testid="login-email-error-message"
          >
            {{ error.$message }}
          </p>
        </div>
        <div>
          <label
            class="text-11 text-light-grey font-bold leading-120 uppercase mb-10 px-16 block"
            for="login-password-input"
          >
            Password
          </label>
          <input
            id="login-password-input"
            v-model="formData.password"
            class="input input--no-shadow w-full"
            :class="{ 'input--error': v$.password.$error }"
            type="password"
            placeholder="Password"
            data-testid="login-password-input"
          >
          <p
            v-for="error in v$.password.$errors"
            :key="error.$uid"
            class="text-red pt-5"
            data-testid="login-password-error-message"
          >
            {{ error.$message }}
          </p>
        </div>

        <div class="mt-10 px-16 grid grid-cols-2 gap-10">
          <BaseButton
            class="bg-turquoise !text-22 !pl-20 text-white hover:bg-white hover:text-purple"
            text="Login"
            type="submit"
            :loading="userStore.loading"
            :disabled="v$.$error"
            animated
            data-testid="login-submit-button"
          >
            <template #icon-right>
              <ChevronRight />
            </template>
          </BaseButton>
          <button
            class="btn btn--size-md !px-10 !bg-charcoal !border-white hover:text-purple hover:!bg-white !justify-center"
            type="button"
            data-testid="login-forgot-password-button"
            @click="$root.setModal(MODAL_TYPE.RESET_PASSWORD, true)"
          >
            Forgot Password?
          </button>
        </div>
      </div>
    </form>
  </BaseDialog>
</template>

<style lang="scss" scoped>
.base-dialog {
  padding: 0;
  @include up('sm') {
    padding: 16px;
  }
}
.login-modal {
  :deep {
    .base-dialog__content {
      color: var(--color-white);
      width: 100%;
      border-radius: 0;
      padding: 127px 40px 50px 40px;
      height: 100%;
      overflow-x: hidden;
      background-color: var(--color-charcoal);
      background-image: url('/assets/img/login-bg-mob.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 0 0;

      @include up('sm') {
        height: auto;
        max-width: 500px;
        padding: 86px 40px 50px 40px;
        border-radius: 20px;
        background-image: url('/assets/img/login-bg.svg');
      }
    }
    .base-dialog__close-btn {
      right: 0;
      top: 20px;
      color: var(--color-purple);

      @include up('sm') {
        top: 0;
        color: var(--color-white);

        &:hover {
          color: var(--color-purple);
        }
      }
    }
  }
}
</style>
