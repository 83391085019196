<script setup lang="ts">
import { PharmacyAnswer } from '@components/answers';
import { QuizNavigation } from '@components/quiz';
import { PharmacyItem, StepContentBody, PharmacySelect } from '@models/quiz';
import { ref, computed } from 'vue';

type Props = {
  modelValue?: PharmacyItem[]
  content?: StepContentBody
  answerContent: PharmacySelect
}

const props = defineProps<Props>();

const emit = defineEmits<{
  next: [];
  back: [];
  'update:modelValue': [value?: PharmacyItem[]]
}>();

const counter = ref(0);

const next = () => {
  if (counter.value === 1) {
    emit('next');
    return;
  }
  counter.value += 1;
};

const back = () => {
  if (counter.value === 0) {
    emit('back');
    return;
  }
  counter.value -= 1;
};

const pharmacyModel = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const fullAddress = computed(() => {
  const item = pharmacyModel.value?.[0];
  if (!item) {
    return;
  }
  return `${item.Address1}, ${item.City} ${item.State}`;
});
</script>

<template>
  <Transition
    name="fade"
    mode="out-in"
  >
    <div v-if="counter === 0">
      <div class="f-row">
        <div class="f-col-5">
          <h1 class="text-24 sm:text-50 sm:font-extralight sm:leading-none">
            {{ content?.header }}
          </h1>

          <div
            v-if="content?.text"
            class="mt-30 text-16 sm:mt-48 sm:text-20"
            v-html="content.text"
          />
        </div>
      </div>

      <PharmacyAnswer
        v-if="counter === 0"
        v-model="pharmacyModel"
        class="mt-30 sm:mt-48"
        :content="answerContent"
      />

      <div class="f-row mt-30 sm:mt-48">
        <div class="f-col-5">
          <QuizNavigation
            :next-disabled="!pharmacyModel"
            @next="next"
            @back="back"
          />
        </div>
      </div>
    </div>

    <div
      v-else-if="counter === 1 && pharmacyModel?.length === 0"
      class="f-row"
    >
      <div class="f-col-4">
        <!-- <template v-if="pharmacyModel?.length === 0"> -->
        <h1 class="text-37 font-extralight leading-none sm:text-50">
          No problem
        </h1>
        <p class="mt-48 text-18 sm:text-20">
          Click continue to proceed, and we will select the closest available pharmacy to the address you provided earlier.
        </p>
        <QuizNavigation
          class="mt-48"
          @next="next"
          @back="back"
        />
        <!-- </template> -->
      </div>
    </div>

    <div
      v-else-if="counter === 1 && !!pharmacyModel?.[0].PharmacyId"
      class="f-row"
    >
      <div class="f-col-4">
        <h1 class="text-37 font-extralight leading-none sm:text-50">
          Your preferred pharmacy is:
        </h1>

        <div class="mt-48">
          <p class="text-37 font-bold leading-110">
            {{ pharmacyModel?.[0].StoreName }}
          </p>
          <p class="text-24 text-purple">
            {{ fullAddress }}
          </p>
        </div>

        <QuizNavigation
          class="mt-48"
          @next="next"
          @back="back"
        />
      </div>
    </div>

    <div
      v-else
      class="f-row"
    >
      <div class="f-col-4">
        <h1 class="text-37 font-extralight leading-none sm:text-50">
          Your preferred pharmacy is not part of our network
        </h1>
        <p class="mt-48 text-18 sm:text-20">
          Click continue to proceed, and we will select the closest available pharmacy to the address you provided earlier.
        </p>
        <QuizNavigation
          class="mt-48"
          @next="next"
          @back="back"
        />
      </div>
    </div>
  </Transition>
</template>
