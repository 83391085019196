<script setup lang="ts">
import { ref, computed } from 'vue';

const currentWeight = ref<number>(200);

const weightToLose = computed<number>(() => {
  return Math.round(currentWeight.value * 0.2);
});
</script>

<template>
  <div class="how-much text-white relative z-10 bg-purple w-full rounded-[20px] p-50">
    <div class="text-40 font-light leading-none mb-40">How much weight could I lose?</div>
    <div class="flex items-start justify-between gap-20 w-full mb-20">
      <div class="text-24 font-light max-w-[200px]">
        Select your current weight
      </div>
      <div class="flex items-end gap-10 text-57 leading-115 font-light">
        <span>{{ currentWeight }}</span>
        <span class="text-20 font-medium mb-8">lbs</span>
      </div>
    </div>
    <div class="py-5">
        <input
            type="range"
            min="140"
            max="400"
            v-model="currentWeight"
            class="range-slider"
        />
    </div>
    <div class="flex justify-between gap-20 w-full mt-20">
      <div class="text-24 font-medium max-w-[185px]">
        Weight you could lose:
      </div>
      <div class="flex items-end gap-10 text-57 leading-115 font-medium">
        <span>{{ weightToLose }}</span>
        <span class="text-20 font-medium mb-8">lbs</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: theme('colors.white-45');
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: theme('colors.white');
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: theme('colors.white');;
  cursor: pointer;
}
</style>
